import React from 'react';
import Masonry from 'react-masonry-css';

import './things.css';

const Thing = props => {
  const thing = props.thing;
  let classes = 'name';
  let baseURL = 'https://images.alkhasov.com/giveaway/';

  if (thing.selected) classes += ' selected';
  if (!thing.available) classes += ' unavailable';

  return (
    <div key={thing.id} className="thing">
      <label className={classes}>
        <input
          type="checkbox"
          id={'thing_' + thing.id}
          defaultChecked={thing.selected}
          onChange={props.handler}
        />
        <img
          data-sizes="auto"
          data-srcset={`${baseURL + thing.images[0]} 1x, ${baseURL +
            thing.images[1]} 2x, ${baseURL + thing.images[2]} 3x`}
          alt={thing.alt}
          className="preview lazyload"
        />
        {/* <img src={thing.image + '?random'} alt="" className="second lazyload" /> */}
        <div className="info">
          <span>{thing.title}</span> <span className="note">{thing.sub}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" />
        </svg>
      </label>
    </div>
  );
};

const Things = props => {
  if (props.loaded) {
    const breakpointColumnsObj = {
      default: 4,
      1200: 4,
      1000: 3,
      700: 2
    };
    return (
      <section id="things">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {props.data.map(thing => {
            return (
              <Thing thing={thing} handler={props.handler} key={thing.id} />
            );
          })}
        </Masonry>
      </section>
    );
  } else {
    return <p>Wait a moment</p>;
  }
};

export default Things;
