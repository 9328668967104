import React from 'react';
import './cover.css';

const Rombus = props => {
  return (
    <div className={props.className}>
      <svg
        width="733"
        height="733"
        viewBox="0 0 733 733"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="367"
          width="517.618"
          height="517.618"
          transform="rotate(-45.0328 0 367)"
          fill={props.color || '#FDFF99'}
        />
      </svg>
    </div>
  );
};

const Circlus = props => {
  return (
    <div className={props.className}>
      <svg
        width="812"
        height="812"
        viewBox="0 0 812 812"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M406 812C181.772 812 0 630.228 0 406H406V0C630.228 0 812 181.772 812 406C812 412.706 811.837 419.374 811.516 426H406V812Z"
          fill={props.color || '#AAF0A4'}
        />
      </svg>
    </div>
  );
};

const Starus = props => {
  return (
    <div className={props.className}>
      <svg
        width="648"
        height="748"
        viewBox="0 0 648 748"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M324 0L395.428 250.284L647.893 187L466.855 374L647.893 561L395.428 497.716L324 748L252.572 497.716L0.106506 561L181.145 374L0.106506 187L252.572 250.284L324 0Z"
          fill={props.color || '#FFCECE'}
        />
      </svg>
    </div>
  );
};

const Inner = props => {
  /* if (props.lang === 'ru') { */
  return (
    <>
      <h1>
        Список вещей Маги, которые&nbsp;ищут&nbsp;нового, клевого&nbsp;владельца
      </h1>
      <h2>
        Как получить:
        <ol>
          <li>Выбери понравившиеся</li>
          <li>Прочти «ЧаВо»</li>
          <li>Заполни форму</li>
          <li>Клацни «Запросить»</li>
        </ol>
      </h2>
    </>
  );
  /*   } else
    return (
      <>
        <h1>
          A list of Maga's things that&nbsp;are looking for
          a&nbsp;new,&nbsp;nice owner.
        </h1>
        <h2>
          How to get:
          <ol>
            <li>Select thing(s) you like</li>
            <li>Fill the form</li>
            <li>Push the request button</li>
          </ol>
        </h2>
      </>
    ); */
};

const Cover = () => {
  return (
    <>
      <section id="cover">
        <Rombus className="shape rombus_2" />
        <Circlus className="shape circlus_2" />
        <Starus className="shape starus_2" />
        <Inner lang={navigator.language || navigator.userLanguage} />
      </section>
      {/*  <video autoplay="" muted="" loop={true}>
        <source
          src="https://images.alkhasov.com/portfolio/27_arila_logo_reveal.mp4"
          type="video/mp4"
        />
      </video> */}
    </>
  );
};

export default Cover;
