import React from 'react';
import './faq.css';

const Faq = props => {
  return (
    <section id="faq">
      <h1 className="pale-yellow">ЧаВо</h1>
      <ul className="headline">
        <li>
          <h4>Я могу получить?</h4>
          <span>
            Да, любой может получить доступный лот из&nbsp;списка в&nbsp;порядке
            очереди запроса.
          </span>
        </li>
        <li>
          <h4>
            Как мне проверить, что меня не&nbsp;обманывают с&nbsp;очередью?
          </h4>
          <span>
            Все запросы автоматически публикуются в&nbsp;
            <a href="https://t-do.ru/giveaway_alkhasov">телеграм канале</a>.
            Текущий владелец лота оставляет за собой право исключить любой
            запрос с&nbsp;объяснением причин.
          </span>
        </li>
        <li>
          <h4>Сколько можно получить?</h4>
          <span>
            Отдаю не больше двух&nbsp;лотов на&nbsp;одного человека. Можно
            выбрать сколько угодно, но&nbsp;в&nbsp;запрос пройдут только
            случайные две из&nbsp;выбранных.
          </span>
        </li>
        <li>
          <h4>Как забрать?</h4>
          <span>
            За вещами надо будет&nbsp;заглянуть лично, Волгоград,
            пл.&nbsp;Советская.
          </span>
        </li>
        <li>
          <h4>Обмен?</h4>
          <span>
            Нет, вещи могут перейти к&nbsp;новому владельцу только абсолютно
            безвозмездно.
          </span>
        </li>
        <li>
          <h4>В урну?</h4>
          <span>
            Нельзя, с ними со&nbsp;всеми связана какая-нибудь история, поэтому
            хранились, либо просто полезная вещь, ну или интересная
            на&nbsp;крайний случай, а&nbsp;барахло — выкидывается. Главное, что
            они все готовы сменить владельца.
          </span>
        </li>
        <li>
          <h4>Старье?</h4>
          <span>Не-а, новое, либо почти как&nbsp;новое.</span>
        </li>
        <li>
          <h4>Возникли вопросы или&nbsp;появились&nbsp;ошибки?</h4>
          <span>
            Стучи в&nbsp;<a href="https://t-do.ru/alkhasov">телеграм</a>
          </span>
        </li>
      </ul>
    </section>
  );
};

export default Faq;
