import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import './form.css';

const isFormReady = props => {
  const { name, contact, message, selected } = props;
  if (
    name.length >= 2 &&
    contact.length >= 2 &&
    message.length >= 2 &&
    selected.length > 0
  )
    return true;
  else return false;
};

class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      name: '',
      contact: '',
      message: ''
    };
    console.log(props);
  }

  handleSubmit(e) {
    e.preventDefault();

    function randomize(things, length) {
      if (things.length <= length) return things.map(e => e);
      return things
        .map(x => ({ x, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map(a => a.x)
        .slice(0, length || things.length);
    }

    const selected = randomize(this.props.selected, 2);

    let msg = JSON.stringify({
      message: {
        ...this.state,
        selected: this.props.selected
      },
      formatted: `*${this.state.name}* requested:${selected.map(
        thing => `
${thing.title} _(id:${thing.id})_`
      )}.`,
      full: `*${this.state.name}* requested:${selected.map(
        thing => `
${thing.title} _(id:${thing.id})_`
      )}

${this.state.message}

${this.state.contact}`
    });
    console.log(msg);

    fetch('https://tlgb.alkhasov.com/message', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: msg
    })
      .then(res => {
        this.props.clear();
        return res;
      })
      .catch(err => {
        console.log(err);
      });

    /*     alert(JSON.stringify(data));
    this.props.clear();
    this.setState({
      name: '',
      contact: '',
      message: '',
      requested: true
    }); */
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  render() {
    let button;
    if (this.props.selected.length > 0)
      button = (
        <button
          type="submit"
          className="submit-button"
          disabled={
            !isFormReady({
              ...this.state,
              selected: this.props.selected
            })
          }
        >
          {this.props.requested ? 'Запрос отправлен' : 'Запросить'}
        </button>
      );
    else {
      button = (
        <button type="button" className="submit-button" disabled>
          Ничего не выбрано
        </button>
      );
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <input
          id="name"
          type="text"
          label="Имя"
          placeholder="Имя"
          value={this.state.name}
          onChange={this.handleChange}
          className="text-input headline"
          disabled={this.props.requested}
          required
        />
        <input
          id="contact"
          type="text"
          label="Связь"
          placeholder="Тлг, Инст или +7"
          value={this.state.contact}
          onChange={this.handleChange}
          className="text-input headline"
          disabled={this.props.requested}
          required
        />
        <TextareaAutosize
          id="message"
          label="Message"
          placeholder="Сообщение"
          value={this.state.message}
          onChange={this.handleChange}
          className="text-area headline"
          disabled={this.props.requested}
          required
        />
        {button}
      </form>
    );
  }
}

const Selected = props => {
  console.log(props, Boolean(props.data.length));
  if (!Boolean(props.data.length)) return <></>;
  else
    return (
      <div className="selected-list">
        <ul>
          {props.data.map(thing => (
            <li key={thing.id}>{thing.title}</li>
          ))}
        </ul>
      </div>
    );
};

const Form = props => {
  return (
    <section id="form">
      <h1 className="pale-red">Форма запроса</h1>
      <Selected data={props.selected} />
      <MyForm {...props} />
    </section>
  );
};

export default Form;
