import React, { Component } from 'react';
import './App.css';

import { Cover, Things, Faq, Form } from './sections';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

class App extends Component {
  constructor(props) {
    super(props);
    this.selectThingHandler = this.selectThingHandler.bind(this);
    this.selectedList = this.selectedList.bind(this);
    this.clearSelections = this.clearSelections.bind(this);

    this.state = {
      loaded: false,
      requested: false,
      earlier: cookies.get('form-req') || false,
      count: 0
    };
  }

  doCount = cond => {
    return cond ? this.state.count + 1 : this.state.count - 1;
  };

  selectThingHandler(e) {
    //console.log({ a: e.target, c: e.target.checked });
    //e.preventDeault();
    this.setState({
      things: this.state.things.map(thing => {
        if ('' + thing.id === e.target.id.substr(6) && thing.available) {
          //console.log(`touch`, e.target, i);
          thing.selected = e.target.checked;
        }
        return thing;
      }),
      count: this.doCount(e.target.checked)
    });
  }

  clearSelections() {
    cookies.set('form-req', true, { path: '/' });
    this.setState({
      things: this.state.things.map(thing => {
        thing.selected = false;
        return thing;
      }),
      requested: true,
      earlier: true
    });
  }

  selectedList() {
    if (!this.state.loaded) return [];
    const list = this.state.things.filter(thing => thing.selected);
    return list.map(thing => {
      return { id: thing.id, title: thing.title };
    });
  }

  componentDidMount() {
    if (!this.state.loaded) {
      fetch('https://data.alkhasov.com/giveaway/data.json')
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.setState({ loaded: true, things: data, requested: false });
          return data;
        });
    }
  }

  render() {
    return (
      <div className="container">
        <Cover />
        <Things
          loaded={this.state.loaded}
          data={this.state.things}
          handler={this.selectThingHandler}
        />
        {/* <Selected loaded={this.state.loaded} data={this.state.things} /> */}
        <Faq />
        {this.state.earlier ? (
          <h4 className="headline" style={{ color: 'hsl(0, 0%, 80%)' }}>
            Запрос отправлен, cпасибо!
          </h4>
        ) : this.state.loaded ? (
          <Form
            selected={this.selectedList()}
            clear={this.clearSelections}
            requested={this.state.requested}
          />
        ) : (
          <h4 className="headline">Секунду, форма грузится</h4>
        )}
      </div>
    );
  }
}

export default App;
